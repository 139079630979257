import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import Card from '../components/Card'

const Projects = () => {
  return (
    <Layout>
      <SEO title="Projekt" description="Projekt av AN Takplat" />
      <Container>
        <PageTitle>Projekt</PageTitle>
        Projekt av AN Takplat AB.
      </Container>
    </Layout>
  )
}

export default Projects
